import React from 'react'
import Figure from './Figure'

import styles from './serializers.module.css'


const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    offerReference: ({node}) => (
      <span>
        <a rel="noopener" href={node.offer.url} target="_blank">{node.offer.title}</a> 
      </span>
    ),
    offerPrimaryReference: ({node}) => (
      <span>
      <div className={styles.primaryoffer}>
        <div className={styles.primaryoffer_imgdiv} dangerouslySetInnerHTML={{__html: node.offer.description}}></div>
        <p>
          <a rel="noopener" className={styles.primaryoffer_title} href={node.offer.url} target="_blank">{node.offer.title}</a>
        </p>
        <p className={styles.primaryoffer_text}>
          {node.offer.summary}
        </p>
        <a rel="noopener" className={styles.primaryoffer_btn} href={node.offer.url}>Check Price</a>
      </div>
      <div className={styles.primaryoffer_after}></div>
      </span>
    ),

    mainImage: Figure
  }
}

export default serializers
